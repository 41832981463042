.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Homepage * {
    margin: 10px;
}

.InputField {
    font-size: calc(10px + 2vmin);
}

.SubmitButton {
    padding: 5px 15px;
    font-size: calc(10px + 2vmin);
}

.EditTimer {
    padding: 10px;
}

#RectangleRed {
    position: fixed;
    z-index: 1;
    width: 50%;
    height: 100%;
    background: darkred;
    left: 0;
}

#RectangleBlue {
    position: fixed;
    z-index: 1;
    width: 50%;
    height: 100%;
    background: darkblue;
    right: 0;
    opacity: 0;
}